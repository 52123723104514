const url = {
    enterprise_yuqing_gsgg: '/service-insight/yuqing/gsgg', //公司公告
    enterprise_yuqing_rdxw: '/service-insight/yuqing/rdxw', // 热点新闻
    enterprise_yuqing_zfpl: '/service-insight/yuqing/zfpl', //政府纰漏
    enterprise_yuqing_yjbg: '/service-insight/yuqing/yjbg', // 研究报告
    enterprise_yuqing_bk: '/service-insight/yuqing/bk', //板块
    enterprise_yuqing_zbk: '/service-insight/yuqing/zbk', // 子板块
       
       

    
}
export default url








