import { post, get } from "@/utils/http";
import url from "./url_publicSentiment";

//公司公告
export function post_enterprise_yuqing_gsgg(params) {
    return post({ url: url.enterprise_yuqing_gsgg, params })
}
// 热点新闻
export function post_enterprise_yuqing_rdxw(params) {
    return post({ url: url.enterprise_yuqing_rdxw, params })
}
//政府纰漏
export function post_enterprise_yuqing_zfpl(params) {
    return post({ url: url.enterprise_yuqing_zfpl, params })
}
// 研究报告
export function post_enterprise_yuqing_yjbg(params) {
    return post({ url: url.enterprise_yuqing_yjbg, params })
}
//板块
export function get_enterprise_yuqing_bk(params) {
    return get({ url: url.enterprise_yuqing_bk, params })
}
// 子板块
export function get_enterprise_yuqing_zbk(params) {
    return get({ url: url.enterprise_yuqing_zbk, params })
}
//政策法规国家维度
export function get_zcfggj(params) {
    return get({ url: '/service-insight/yuqing/zcfggj', params })
}
// 政策法规分类维度(包括一级二级三级)
export function get_zcfgfl(params) {
    return get({ url: '/service-insight/yuqing/zcfgfl', params })
}
// 政策法规区维度
export function get_zcfgqu(params) {
    return post({ url: '/service-insight/yuqing/zcfgqu', params })
}
// 政策法规省维度
export function get_zcfgsheng(params) {
    return get({ url: '/service-insight/yuqing/zcfgsheng', params })
}
// 政策法规市维度
export function get_zcfgshi(params) {
    return post({ url: '/service-insight/yuqing/zcfgshi', params })
}
// 政策法规
export function get_zfpl(params) {
    return post({ url: '/service-insight/yuqing/zfpl', params })
}
// 城市更新
export function get_csgx(params) {
    return post({ url: '/service-insight/yuqing/csgx', params })
}
// 城市更新分类维度(包括一级二级三级)
export function get_csgxfl(params) {
    return get({ url: '/service-insight/yuqing/csgxfl', params })
}
// 城市更新区维度
export function get_csgxqu(params) {
    return post({ url: '/service-insight/yuqing/csgxqu', params })
}
// 城市更新省维度
export function get_csgxsheng(params) {
    return get({ url: '/service-insight/yuqing/csgxsheng', params })
}
// 城市更新市维度
export function get_csgxshi(params) {
    return post({ url: '/service-insight/yuqing/csgxshi', params })
}

