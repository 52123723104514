<template>
    <div class="home">
        <Card>
            <Form :model="searchForm" :label-width="80" inline>
                <FormItem label="国家">
                    <Select
                        v-model="searchForm.gj"
                        multiple
                        :max-tag-count="0"
                        @on-change="(event) => setData('gj',event)"
                        style="width:100px;"
                    >
                        <Option v-for="item in gjOption" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="省">
                    <Select
                        v-model="searchForm.sheng"
                        multiple
                        :max-tag-count="0"
                        @on-change="(event) => setData('sheng',event)"
                        style="width:100px;"
                    >
                        <Option v-for="item in sfOption" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="市">
                    <Select
                        v-model="searchForm.shi"
                        multiple
                        :max-tag-count="0"
                        @on-change="(event) => setData('shi',event)"
                        style="width:100px;"
                    >
                        <Option v-for="item in csOption" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="行政区">
                    <Select
                        v-model="searchForm.qu"
                        multiple
                        :max-tag-count="0"
                        @on-change="(event) => setData('qu',event)"
                        style="width:100px;"
                    >
                        <Option v-for="item in quOption" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="分类一">
                    <Select
                        v-model="searchForm.yjfl"
                        multiple
                        filterable
                        :max-tag-count="0"
                        @on-change="(event) => setData('yjfl',event)"
                        style="width:120px;"
                    >
                        <Option v-for="item in fl.yjfl" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="分类二">
                    <Select
                        v-model="searchForm.ejfl"
                        multiple
                        filterable
                        :max-tag-count="0"
                        @on-change="(event) => setData('ejfl',event)"
                        style="width:120px;"
                    >
                        <Option v-for="item in fl.ejfl" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="分类三">
                    <Select
                        v-model="searchForm.sjfl"
                        multiple
                        filterable
                        :max-tag-count="0"
                        @on-change="(event) => setData('sjfl',event)"
                        style="width:120px;"
                    >
                        <Option v-for="item in fl.sjfl" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="发布日期">
                    <DateRange
                        v-model="searchForm.fbrq"
                        @change="(value) => this.handleChangeOption('发布日期', 'date', 'fbrq', value)"
                    ></DateRange>
                </FormItem>
                <FormItem label="关键字">
                    <Input
                        name="gjz"
                        v-model="searchForm.gjz"
                        placeholder="请输入关键字"
                        style="width:200px;"
                    />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleSearch">查询</Button>
                </FormItem>
            </Form>
        </Card>
        <div class="tagbox">
            <!-- <template v-if="Object.keys(selectedList).length !== 0">
                已选条件：
                <template v-for="item in selectedList">
                    <span v-if="item !== null" :key="item.formItem">
                        <Tag
                            v-for="(name, index) in item.data"
                            :key="name"
                            type="border"
                            closable
                            @on-close="() => handleClose(index, item)"
                        >
                            <span v-if="item">{{item.title}}:</span>
                            <span>{{name}}</span>
                        </Tag>
                    </span>
                </template>
            </template>-->
        </div>
    </div>
</template>

<script>
import { get_zcfggj, get_zcfgfl, get_zcfgqu, get_zcfgsheng, get_zcfgshi } from '@/api/publicSentiment'
import tagBoxMixin from '@/views/share/tagBoxMixin'
import dateHandleMixin from '@/views/share/dateHandleMixin'
export default {
    name: 'SearchPanel',
    components: {
    },
    mixins: [tagBoxMixin, dateHandleMixin],
    data() {
        return {
            searchForm: {
                gj: [], //
                sheng: [], //
                fbrq: ['', ''], // 公告日期
                size: 10,
                current: 1,
                gjz: '',
                yjfl: [],
                ejfl: [],
                sjfl: [],
                shi: [],
                qu: []
            },
            selectedList: {}, //已选条件
            gjOption: [],
            sfOption: [],
            csOption: [],
            quOption: [],
            fl: {}
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        handleSearch() {
            const { fbrq } = this.searchForm;
            let searchForm = this.handleDateRule(fbrq, 'fbrq', '发布日期', this.searchForm);
            if (searchForm === null) {
                return;
            }
            this.$emit('submit', searchForm);
        },
        setData(type, event) {
            this.searchForm[type] = event
            if (type == 'sheng') {
                get_zcfgshi({
                    ss: event
                }).then(res => {
                    this.csOption = res
                })
            }
            if (type == 'shi') {
                get_zcfgqu({
                    ss: event
                }).then(res => {
                    this.quOption = res
                })
            }
        },
        init() {
            get_zcfggj().then(res => {
                this.gjOption = res
            })
            get_zcfgsheng().then(res => {
                this.sfOption = res
            })
            get_zcfgfl().then(res => {
                this.fl = res
            })
        }
    }
}
</script>
