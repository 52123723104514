
<template>
    <div class="right-box">
        <div class="topText" ref="top">
            <div
                class="content"
                ref="content"
                :style="{left:-left+'px'}"
            >为了便于用户无偏差理解政策及相关舆情信息、明确信息源、详细了解信息发布背景和过程等，舆情监测政策法规、城市更新两个模块提供信息分类查询功能，详情信息查阅将跳转信息源，但来源均为政府部门及相关单位，政府官方背景或指定信息披露平台等，经严格审核、确认真实可靠的信息。</div>
        </div>
        <Tabs value="government" class="tab-box">
            <TabPane label="政策法规" name="government">
                <dataplat-government></dataplat-government>
            </TabPane>
            <TabPane label="企业公告" name="enterpriseAnnouncement">
                <dataplat-notice></dataplat-notice>
            </TabPane>
            <TabPane label="城市更新" name="statistics">
                <dataplat-news></dataplat-news>
            </TabPane>
            <!-- <TabPane label="研究报告"
               name="research">
        <dataplat-research-report></dataplat-research-report>
            </TabPane>-->
        </Tabs>
    </div>
</template>

<script>
// @ is an alias to /src
import Notice from "./hotspot/Notice"
import News from "./hotspot/News"
import Government from "./hotspot/Government"
import ResearchReport from "./hotspot/ResearchReport"
import dataReports from '@/mixins/dataReports'
export default {
    name: 'notice',
    mixins: [dataReports],
    components: {
        'dataplat-notice': Notice,
        'dataplat-news': News,
        'dataplat-government': Government,
        // 'dataplat-research-report': ResearchReport,
    },
    data() {
        return {
            left: -10
        }
    },
    mounted() {

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.font = `14px 'Avenir', Helvetica, Arial, sans-serif`;
        const width = ctx.measureText('为了便于用户无偏差理解政策及相关舆情信息、明确信息源、详细了解信息发布背景和过程等，舆情监测政策法规、城市更新两个模块提供信息分类查询功能，详情信息查阅将跳转信息源，但来源均为政府部门及相关单位，政府官方背景或指定信息披露平台等，经严格审核、确认真实可靠的信息。').width + 4;
        const contentWidth = this.$refs.content.offsetWidth
        setInterval(() => {
            if (this.left < width - contentWidth + 50) {
                this.left += 5
            } else {
                this.left = -20
            }
        }, 200)

    }
}
</script>
<style lang="scss">
.topText {
    height: 40px;
    line-height: 40px;
    border: 1px solid #e2e2e2;
    color: #515a6e;
    background: rgba(0, 0, 0, 0.02);
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    transition: 0;
    .content {
        height: 40px;
        white-space: nowrap;
        position: absolute;
        font-size: 14px;
    }
}
</style>
