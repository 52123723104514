<template>
    <div class="home">
        <dataplat-search @submit="handleSubmit"></dataplat-search>
        <dataplat-content
            :current="searchForm.page"
            :list="tableList"
            :total="total"
            @changePage="handleChangePage"
            @changePageSize="handleChangePageSize"
        ></dataplat-content>
    </div>
</template>

<script>
import SearchPanel from "../components/hotspot/notice/SearchPanel"
import ListPanel from "../components/share/ListPanel"
import { post_enterprise_yuqing_yjbg } from '@/api/publicSentiment'
export default {
    name: 'notice',
    components: {
        'dataplat-search': SearchPanel,
        'dataplat-content': ListPanel,
    },
    computed: {
        gpdm() {
            return this.$store.state.componeyCode
        }
    },
    data() {
        return {
            searchForm: {
                size: 10,
                current: 1,
            },
            tableList: [],
            total: 0
        }
    },
    mounted() {
        this.handleSubmit(this.searchForm);
    },
    methods: {
        handleChangePage(page) {
            this.searchForm.current = page;
            this.handleSubmit(this.searchForm);
        },
        handleChangePageSize(page) {
            this.searchForm.size = page;
            this.handleSubmit(this.searchForm);
        },
        handleSubmit(params) {
            const searchForm = Object.assign(params, {
                size: 10,
                gpdm: this.gpdm // 股票代码
            });
            this.searchForm = searchForm;
            post_enterprise_yuqing_yjbg(searchForm).then(data => {
                this.tableList = data.records;
                this.total = data.total;
            }).catch(e => {

            });
        }
    }
}

</script>
