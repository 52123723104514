<template>
	<div>
		<template v-if="total !== 0">
			<div class="list-item" v-if="!['notice'].includes(type)">
				<div class="left title">标题</div>
				<div class="right title" v-if="type!='news'">发布部门</div>
				<div class="right title">分类一</div>
				<div class="right title">分类二</div>
				<div class="right title">分类三</div>
				<div class="right title" v-if="type!='news'">有效性</div>
				<div class="right title">发布日期</div>
			</div>
			<div v-for="item in list" :key="item.id" class="list-item">
				<div class="left">
					<Tooltip max-width="500" theme="light" :content="`${item.bkmc?item.bkmc:''}${item.bt}`">
						<Icon type="md-arrow-dropright" />
						<span class="gglx" v-if="item.bkmc">{{item.bkmc}}</span>
						<a :href="item.url" target="_blank">{{ item.bt }}</a>
					</Tooltip>
				</div>
				<div class="right" v-if="!['notice','news'].includes(type)">
					<Tooltip max-width="300" theme="light" :content="item.fbbm">{{item.fbbm}}</Tooltip>
				</div>
				<div class="right" v-if="!['notice'].includes(type)">
					<Tooltip max-width="300" theme="light" :content="item.fl1">{{item.fl1}}</Tooltip>
				</div>
				<div class="right" v-if="!['notice'].includes(type)">
					<Tooltip max-width="300" theme="light" :content="item.fl2">{{item.fl2}}</Tooltip>
				</div>
				<div class="right" v-if="!['notice'].includes(type)">
					<Tooltip max-width="300" theme="light" :content="item.fl3">{{item.fl3}}</Tooltip>
				</div>
				<div class="right" v-if="!['notice','news'].includes(type)">
					<Tooltip max-width="300" theme="light" :content="item.yxx">{{item.yxx}}</Tooltip>
				</div>
				<div class="right">
					<Tooltip max-width="300" theme="light" :content="item.rq|formatDate">{{item.rq|formatDate}}</Tooltip>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="no-data">暂无数据</div>
		</template>
		<Page
			:current="current"
			class="pagetion"
			:total="total"
			prev-text="上一页"
			next-text="下一页"
			show-elevator
			show-sizer
			@on-change="handleChangePage"
		/>
	</div>
</template>
<script>
	export default {
		name: "listPanel",
		props: {
			list: Array,
			total: Number,
			current: {
				type: [String, Number],
				default: 1,
			},
			type: {
				type: String,
				default: "other",
			},
		},
		watch: {
			current(newValue, oldValue) {
				this.current = newValue;
			},
		},
		filters: {
			formatDate: function (val) {
				const value = new Date(val);
				const year = value.getFullYear();
				const month = value.getMonth() + 1;
				const day = value.getDate();
				const hour = value.getHours();
				const minutes = value.getMinutes();
				const seconds = value.getSeconds();
				return (
					year +
					"-" +
					(month >= 10 ? month : "0" + month) +
					"-" +
					(day >= 10 ? day : "0" + day)
				);
			},
		},
		data() {
			return {};
		},
		methods: {
			handleChangePage(page) {
				this.$emit("changePage", page);
			},
		},
	};
</script>

<style scoped lang="scss">
	.list-item {
		display: flex;
		padding: 12px;
		border-bottom: 1px dashed #d6d7d9;
		justify-content: space-between;
		.title {
			font-weight: bold;
			color: #000 !important;
		}
		.left {
			flex: 2;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			a {
				flex: 1;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.right {
			flex: 0.5;
			color: #999;
			text-align: center;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.gglx {
			background: rgba(246, 250, 255, 1);
			padding: 2px 6px;
			color: #1562d6;
			border-radius: 2px;
			border: 1px solid rgba(21, 98, 214, 1);
			display: inline;
			margin-right: 10px;
		}
	}
	.no-data {
		border: 1px solid #e8eaec;
		padding: 20px;
		text-align: center;
	}
</style>
