<template>
    <div class="home">
        <Card>
            <Form :model="searchForm" :label-width="120" inline>
                <FormItem label="关键字">
                    <Input name="gjz" v-model="searchForm.gjz" placeholder="请输入关键字" @on-change="(event) => this.handleChangeOption('关键字', 'string','gjz', event.target.value)"/>
                </FormItem>
                <FormItem label="公告日期">
                    <DateRange v-model="searchForm.rq" @change="(value) => this.handleChangeOption('公告日期', 'date', 'rq', value)"></DateRange>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleSearch">查询</Button>
                </FormItem>
            </Form>
        </Card>
        <div class="tagbox">
            <template v-if="Object.keys(selectedList).length !== 0">
                已选条件：
                <template v-for="item in selectedList" >
                    <span v-if="item !== null" :key="item.formItem">
                        <Tag v-for="(name, index) in item.data" :key="name" type="border" closable @on-close="() => handleClose(index, item)">
                            <span v-if="item">{{item.title}}:</span>
                            <span  >{{name}} </span>
                        </Tag>
                    </span>
                </template>
            </template>
        </div>
  </div>
</template>

<script>
import { get_enterprise_dim_qtrzfs } from '@/api/houseEnterprise'
import tagBoxMixin from '@/views/share/tagBoxMixin'
import dateHandleMixin from '@/views/share/dateHandleMixin'
export default {
    name: 'SearchPanel',
    components: {
    },
    mixins: [tagBoxMixin, dateHandleMixin],
    data () {
        return {
            searchForm: {
                gjz: '', //
                rq: ['', ''], // 公告日期
                size: 10,
                current: 1,
            },
            selectedList:{} //已选条件
        }
    },

    methods: {
        handleSearch(){
            const { rq } = this.searchForm;
            let searchForm = this.handleDateRule(rq, 'rq', '公告日期', this.searchForm);
            if(searchForm === null){
                return;
            }
            this.$emit('submit', searchForm);
        }
    }
}
</script>
